import { ComponentProps, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useMutation } from '@/hooks/useMutation'
import { PostLoginResBody, PostLoginReqBody } from '@/apiInterface/login'
import { getConfig } from '@/express/getConfig'
import { Presentation, FormDataType } from './presentation'
import { ErrorTypeNotificateMessage, ErrorTypeForcePasswordChange } from '@/error'

export type initialError =
  | {
      type: string
      message: string
    }
  | undefined
export type initialLoginId = string | null | undefined

export type Props = {
  readonly initialError: initialError
  readonly initialLoginId: initialLoginId
  readonly intercomAppId: string
} & Pick<ComponentProps<typeof Presentation>, 'accessDate'>

export const Login = ({ accessDate, initialError, initialLoginId, intercomAppId }: Props) => {
  const { status, mutate, error, data } = useMutation<PostLoginReqBody, PostLoginResBody>({
    uri: '/xhr/v1.0.0/login',
    method: 'POST',
  })

  const [snackbarVisible, setSnackbarVisible] = useState(error?.type === ErrorTypeNotificateMessage)
  const confirmedError = () => setSnackbarVisible(false)
  useEffect(() => {
    if (error?.type === ErrorTypeNotificateMessage) {
      setSnackbarVisible(true)
    }
  }, [setSnackbarVisible, error])
  const router = useRouter()
  const onSubmit = (formData: FormDataType) => mutate({ ...formData })
  if (error?.type === ErrorTypeForcePasswordChange) {
    router.push(`/account/force_password_change/`)
  }
  if (status === 'succeeded') {
    const { publicRuntimeConfig } = getConfig()
    const redirectTo =
      data?.redirectUri && data.redirectUri !== '/' && data.redirectUri !== '/v1.0.0/login'
        ? data.redirectUri
        : publicRuntimeConfig.BFF_DEFAULT_RP_REDIRECT_URL

    router.push(redirectTo)
    return null
  }
  return (
    <Presentation
      isSending={status === 'sending'}
      errorType={error?.type || initialError?.type}
      errorMessage={error?.message || initialError?.message}
      snackbarVisible={snackbarVisible}
      confirmedError={confirmedError}
      onSubmit={onSubmit}
      accessDate={accessDate}
      initialLoginId={initialLoginId}
      intercomAppId={intercomAppId}
    />
  )
}
