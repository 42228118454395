import nextConfig from 'next/config'

export type ServerRuntimeConfig = {
  readonly BFF_PORT?: string
  readonly BFF_AWS_REGION?: string
  readonly BFF_AWS_ENDPOINT?: string
  readonly BFF_DYNAMO_SESSION_TABLE_NAME?: string
  readonly BFF_NEGRONI_API_BASE_URL: string
  readonly BFF_DAIQUIRI_API_BASE_URL: string
  readonly BFF_SESSION_SECRET: string
  readonly BFF_CSRF_JWS_SECRET: string
  readonly BFF_NEGRONI_API_AUTHENTICATION_SECRET: string
  readonly BFF_NEGRONI_API_KEYID: string
  readonly BFF_DAIQUIRI_API_AUTHENTICATION_SECRET: string
  readonly BFF_DAIQUIRI_API_KEYID: string
}

export type PublicRuntimeConfig = {
  readonly BFF_ORIGIN?: string
  readonly BFF_RUNTIME_ENV?: 'local' | 'fm' | 'dev' | 'stage' | 'prd'
  readonly BFF_DEFAULT_RP_REDIRECT_URL: string
  readonly BFF_INTERCOM_APP_ID: string
}

export type NextConfig = {
  readonly serverRuntimeConfig: ServerRuntimeConfig
  readonly publicRuntimeConfig: PublicRuntimeConfig
}

export function getConfig() {
  return nextConfig() as NextConfig
}
