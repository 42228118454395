import Head from 'next/head'
import { InferGetServerSidePropsType } from 'next'
import { combineGssp } from '@/gssp/combineGssp'
import { csrfToken } from '@/gssp/csrfToken'
import { accessDate } from '@/gssp/accessDate'

import { Login } from '@organisms/Login'
import { InstrospectIdTokenHintResponse, InstrospectIdTokenHintRequest } from 'agreed/negroni-api/introspectIDTokenHint'
import { GetUserResponse } from 'agreed/daiquiri-api/getUser'
import { ErrorMessage, ErrorTypePageMessage } from '@/error'

import { getConfig } from '@/express/getConfig'
import { gtm } from '@/gssp/gtm'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>

export default function LoginPage(props: Props) {
  const { initialError, initialLoginId } = props
  const accessDateISOString = (props as any).accessDateISOString

  const { publicRuntimeConfig } = getConfig()

  return (
    <>
      <Head>
        <title>ログイン | bellface</title>
      </Head>
      <Login
        accessDate={new Date(accessDateISOString)}
        initialError={initialError}
        initialLoginId={initialLoginId}
        intercomAppId={publicRuntimeConfig.BFF_INTERCOM_APP_ID}
      />
    </>
  )
}

export const getServerSideProps = combineGssp(
  gtm,
  accessDate,
  csrfToken({
    setClaimParams: ({ query }): Record<string, string> => {
      const rawRedirectUri = query.post_login_redirect_uri
      const postLoginRedirectUri = !rawRedirectUri
        ? ''
        : Array.isArray(rawRedirectUri)
        ? rawRedirectUri[0]
        : rawRedirectUri

      const rawIdToken = query.idToken
      const idTokenHint = !rawIdToken ? '' : Array.isArray(rawIdToken) ? rawIdToken[0] : rawIdToken

      const rawInvitationCode = query.invitation_code
      const invitationCode = !rawInvitationCode
        ? ''
        : Array.isArray(rawInvitationCode)
        ? rawInvitationCode[0]
        : rawInvitationCode

      const rawSharedInvitationCode = query.shared_invitation_code
      const sharedInvitationCode = !rawSharedInvitationCode
        ? ''
        : Array.isArray(rawSharedInvitationCode)
        ? rawSharedInvitationCode[0]
        : rawSharedInvitationCode

      return {
        postLoginRedirectUri,
        idTokenHint,
        invitationCode,
        sharedInvitationCode,
      }
    },
  }),
  async ({ req, query }) => {
    let redirectURI: string | null = null
    let idTokenHint: string | null = null
    const { post_login_redirect_uri, id_token_hint } = query
    if (typeof post_login_redirect_uri === 'string') {
      redirectURI = post_login_redirect_uri
    }
    if (typeof id_token_hint === 'string') {
      idTokenHint = id_token_hint
    }

    const { publicRuntimeConfig } = getConfig()
    if (req.session.userSession) {
      const callbackURI = redirectURI ?? publicRuntimeConfig.BFF_DEFAULT_RP_REDIRECT_URL
      return {
        redirect: {
          permanent: false,
          destination: callbackURI,
        },
      }
    }

    let initialLoginId: string | null = null
    if (idTokenHint) {
      let userId: number | null = null
      try {
        const { data } = await req
          .negroniApiClient()
          .post<InstrospectIdTokenHintResponse>(`/v1.0.0/id_token_hint/introspect`, {
            id_token_hint: idTokenHint,
            redirectURI: redirectURI, // Currently no usecase
          } as InstrospectIdTokenHintRequest)
        if (!data.verified) {
          req.log.debug({ msg: 'id_token_hint fail verification', data })
          return {
            props: {
              initialError: {
                type: ErrorTypePageMessage,
                message: ErrorMessage.IDTokenHintVerificationFailure,
              },
            },
          }
        }
        userId = Number(data.sub)
      } catch (e) {
        return {
          redirect: {
            permanent: false,
            destination: '/error',
          },
        }
      }

      try {
        const { data } = await req.daiquiriApiClient().get<GetUserResponse>(`/v1.0.0/users/${userId}`)
        initialLoginId = data.login_id
      } catch (e) {
        return {
          redirect: {
            permanent: false,
            destination: '/error',
          },
        }
      }
    }

    if (!idTokenHint && redirectURI) {
      // in case of OP redirect
      const { publicRuntimeConfig } = getConfig()

      // access absolute path only origin
      const regExp = new RegExp('^' + publicRuntimeConfig.BFF_ORIGIN + '/')
      if (redirectURI.match(/^\w+:\/\//) !== null && redirectURI.match(regExp) === null) {
        req.log.debug({ msg: 'Invalid redirect_url', check: redirectURI, expect: publicRuntimeConfig.BFF_ORIGIN })
        return {
          redirect: {
            permanent: false,
            destination: '/error',
          },
        }
      }
    }

    return {
      props: {
        initialLoginId: initialLoginId,
      },
    }
  },
)
