import { useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import styled, { keyframes } from 'styled-components'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { Colors } from '@/styles'

import { LoginId, LoginPassword } from '@/validations'
import { HelpLinks } from '@/constants'
import { Logo } from '@atoms/Logo'
import { Button } from '@atoms/Button'
import { DailyGreeting } from '@atoms/DailyGreeting'
import { FormError } from '@atoms/FormError'
import { Link } from '@atoms/Link'
import { TextField } from '@molecules/TextField'
import { Panel } from '@organisms/Panel'
import { Notification } from '@organisms/Notification'
import { MultiPanelLayout } from '@layouts/MultiPanel'
import {
  ErrorTypeLoginIdLock,
  ErrorTypeNotJoinTenant,
  ErrorTypePageMessage,
  ErrorTypeDisabledPasswordLogin,
} from '@/error'
import { IntercomProvider } from 'react-use-intercom'

const AccountLock = dynamic(() => import('@organisms/AccountLock'))
const TenantNotJoin = dynamic(() => import('@organisms/TenantNotJoin'))
const DisabledPasswordLogin = dynamic(() => import('@organisms/DisabledPasswordLogin'))

export type FormDataType = {
  readonly loginId: string
  readonly password: string
}

export type Props = {
  readonly isSending: boolean
  readonly errorType?: string
  readonly errorMessage?: string
  readonly confirmedError: () => void
  readonly onSubmit: (data: FormDataType) => void
  readonly accessDate: Date
  readonly snackbarVisible: boolean
  readonly initialLoginId: string | null | undefined
  readonly intercomAppId: string
}

export const Presentation = ({
  isSending,
  errorType,
  errorMessage,
  onSubmit,
  confirmedError,
  accessDate,
  snackbarVisible,
  initialLoginId,
  intercomAppId,
}: Props) => {
  const [passwordVisible, setPasswordVisible] = useState(!!initialLoginId)
  const {
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = useForm<FormDataType>({
    mode: 'all',
    defaultValues: { loginId: initialLoginId ?? undefined },
  })

  const handleClick = useCallback(() => setPasswordVisible(passwordVisible => !passwordVisible), [])
  const handleHideNotification = useCallback(() => confirmedError(), [confirmedError])

  if (errorType === ErrorTypeLoginIdLock) return <AccountLock />
  if (errorType === ErrorTypeNotJoinTenant) return <TenantNotJoin />
  if (errorType === ErrorTypeDisabledPasswordLogin) return <DisabledPasswordLogin />

  return (
    <IntercomProvider appId={intercomAppId} autoBoot>
      <MultiPanelLayout>
        <Panel theme="brand">
          <DailyGreeting hour={accessDate.getUTCHours() + 9} />
          <Logo theme="white" />
          <Link href={HelpLinks.login} iconName="help_outline" color={Colors.textWhite} bold blank>
            ログインできない場合はこちら
          </Link>
        </Panel>
        <StyledPanel title="ログイン">
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            {errorType === ErrorTypePageMessage && errorMessage && <StyledFormError>{errorMessage}</StyledFormError>}
            <StyledLoginId
              id="loginid"
              className={classnames(passwordVisible && 'moveup')}
              label="メールアドレス"
              inputMode="email"
              autoComplete="username"
              placeholder="info@example.com"
              autoFocus
              error={errors?.loginId}
              {...register('loginId', LoginId)}
              disabled={!!initialLoginId}
            />
            <StyledPassword
              id="current-password"
              className={classnames(passwordVisible && 'visible')}
              label="パスワード"
              type="password"
              autoComplete="current-password"
              autoFocus={passwordVisible}
              error={errors.password}
              {...register('password', LoginPassword)}
            />
            {passwordVisible ? (
              <>
                <LinkArea>
                  <Link href="/password_reset_request">パスワードをお忘れですか？</Link>
                </LinkArea>
                <ButtonArea passwordVisible={passwordVisible}>
                  <StyledButton type="submit" disabled={isSending || !(isValid && isDirty)} isSending={isSending}>
                    {isSending ? 'ログイン中...' : 'ログイン'}
                  </StyledButton>
                </ButtonArea>
              </>
            ) : (
              <ButtonArea>
                <Button type="submit" onClick={handleClick} disabled={isSending || !(!errors.loginId && isDirty)}>
                  {isSending ? '送信中...' : '続行'}
                </Button>
              </ButtonArea>
            )}
          </StyledForm>
        </StyledPanel>
        <Notification theme="error" visibility={snackbarVisible} onHide={handleHideNotification}>
          {errorMessage}
        </Notification>
      </MultiPanelLayout>
    </IntercomProvider>
  )
}

const moveUp = keyframes`
  from {
    transform: translateY(59px);
  }

  to {
    transform: translateY(0);
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const StyledPanel = styled(Panel)`
  &.panel& > .title {
    margin-bottom: 16px;
  }
`

const StyledFormError = styled(FormError)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 42px;
`

const StyledLoginId = styled(TextField)`
  z-index: 1;
  margin-top: auto;

  &.moveup {
    margin: 66px 0 8px;
    animation: ${moveUp} 0.4s ease-in-out;
  }
`

const StyledPassword = styled(TextField)`
  display: none;
  opacity: 0;
  animation: ${fadeIn} 0.8s ease-in-out;

  &.visible {
    display: block;
    opacity: 100;
  }
`

const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ButtonArea = styled.div<{ passwordVisible?: boolean }>`
  margin-top: ${({ passwordVisible }) => (passwordVisible ? '24px' : 'auto')};
  text-align: center;
`

const StyledButton = styled(Button)<Pick<Props, 'isSending'>>`
  padding: ${({ isSending }) => isSending && '16px 23px'};
`

const LinkArea = styled.div`
  margin-top: auto;
  font-size: 16px;
  text-align: center;
`
